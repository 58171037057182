<template>
    <div>
        <div id="checkout">
            <!-- Checkout will insert the payment form here -->
        </div>
    </div>
</template>

<script>
import { getREPassOrder } from "../../api/eae";
export default {
    data() {
        return {
            order_id: this.$route.query.order_id,
            user_id: "",
            amount: "",
            currency: "",
        };
    },
    mounted() {
        this.requestOrderInfo()
    },
    methods: {

        requestOrderInfo() {
            getREPassOrder(this.order_id).then((res) => {
                let order = res.data
                this.handleOrderData(this.order_id, order)
            })
        },
        handleOrderData(order_id, order) {
            let amount = order.service_fee + order.total_price
            let line_items = [{
                price_data: {
                    currency: "usd",
                    product_data: {
                        name: order.pass.name,
                    },
                    unit_amount: amount * 100,
                },
                quantity: 1,
            }]
            this.pay(order_id, line_items);
        },

        pay(order_id, line_items) {
            // Initialize Stripe.js
            const stripe = Stripe(
                "pk_live_51P128qP6BucL4HOz2SJY6gWLATlcK5rGdNTnePD780jhsy6ZLf7ZxIHxRE1oBLk9pchjvIoqHUwnPPGB10DmtGOO00yikhqAr0"
            );

            initialize();
            // Fetch Checkout Session and retrieve the client secret
            async function initialize() {
                const fetchClientSecret = async () => {
                    const response = await fetch(
                        "https://web-backend.reachable-edu.com/Stripe/REPassEmbedPay.php",
                        {
                            method: "POST",
                            headers: { "Content-Type": "application/json" },
                            body: JSON.stringify({
                                order_id: order_id,
                                line_items: line_items,
                            }),
                        }
                    );
                    let result = await response.json();
                    const { clientSecret } = result;
                    return clientSecret;
                };

                // Initialize Checkout
                const checkout = await stripe.initEmbeddedCheckout({
                    fetchClientSecret,
                });

                // Mount Checkout
                checkout.mount("#checkout");
            }
        },
    },
};
</script>

<style></style>